<template>
  <div>
    <Header 
      button="Editar"
      title="Gerenciamento de vídeos"
      subtitle="Gerencie a página de material de apoio."
      @click="itemsTemp = [...items], $modal.show('edit')"
    />
    <div v-if="items.length" class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <div v-for="(item, index) in items" :key="index" class="py-3 px-4">
          <p class="text-sm font-medium text-gray-900">{{ item.title }}</p>
          <p class="py-2 xs:mb-2 text-sm text-gray-500 text-justify">{{ item.subtitle }}</p>
          <iframe style="filter: invert(8%)" class="rounded-lg hover:shadow-lg" width="100%" height="315" :src="item.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <t-modal name="edit" class="z-45">
      <p class="text-base font-medium text-gray-800">Editar</p>
      <form @submit.prevent class="mt-4">
        <div v-for="(item, index) in itemsTemp" :key="index" class="grid grid-cols-2 gap-2">
          <div class="col-span-1">
            <label class="block text-sm font-medium text-gray-700">Título</label>
            <input v-model="item.title" type="text" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1 flex items-end space-x-2">
            <div>
              <label class="block text-sm font-medium text-gray-700">Vídeo</label>
              <input v-model="item.video" type="text" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <button @click="itemsTemp.splice(index, 1)" type="button" class="inline-flex items-center p-2 border border-transparent rounded-md shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            </button>
          </div>
          <div class="col-span-2">
            <label class="block text-sm font-medium text-gray-700">Subtítulo</label>
            <input v-model="item.subtitle" type="text" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div v-if="index !== itemsTemp.length - 1" class="border border-gray-200 col-span-2 mt-2 mb-3" />
        </div>
        <div class="flex space-x-3">
          <button @click="itemsTemp.push({ title: '', subtitle: '', video: '' })" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border-2 border-primary shadow-sm text-sm font-medium rounded-md text-primary bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Adicionar
          </button>
          <button @click="save()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Salvar
          </button>
        </div>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import { listingsColl } from '@/firebase'

export default {
  components: { Header: () => import('@/components/layout/header') },

  data() { return { items: [], itemsTemp: [] } },

  mounted() { this.getItems() },
  
  methods: {
    async getItems() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('LearnIt').get()
      .then((result) => { this.items = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter o conteúdo, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async save() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('LearnIt').update({ available: this.itemsTemp.filter(item => item.title && item.video).map(item => { return { title: item.title, subtitle: item.subtitle, video: item.video.replace('/watch?v=', '/embed/') } }) })
      .then(() => { this.$toast.success('Conteúdo atualizado com sucesso') })
      .catch(() => { this.$toast.error('Falha ao salvar o conteúdo, tente novamente') })
      this.getItems()
      this.$modal.hide('edit')
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }  
}
</script>